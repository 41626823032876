import {Col, Row, Form, Button} from "react-bootstrap";
import Dialog from "@mui/material/Dialog";
import React from "react";
import axios from "axios";
import Fade from "react-reveal/Fade";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Sendapplication.css";
import {PHONE, PHONE_WITHOUT_NAME} from "../Confinfo";

const API = require("../API/API_config");

function SendApplication() {
    //Содержимое отправки
    const [name, setName] = React.useState("");
    const [phone, setPhone] = React.useState("");
    const [message, setMessage] = React.useState("");
    //Валидация имени is-valid/is-invalid
    const [namecheck, setNamecheck] = React.useState("");
    const [phonecheck, setPhonecheck] = React.useState("");
    const [messagecheck, setMessagecheck] = React.useState("");
    //Общее состояние формы (оповещения для пользователя)
    const [resMess, setResmes] = React.useState("");

    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };

    //Отправка на сервер

    function clear_inputs() {
        setName("");
        setPhone("");
        setMessage("");
        setPhonecheck("");
        setNamecheck("");
        setMessagecheck("");
    }

    function Send_data(event) {
        if (phonecheck === "is-valid" && namecheck === "is-valid") {
            event.preventDefault();
            const data = {name: name, phone: phone, message: message};
            setResmes("Идёт отправка заявки, подождите");

            axios.post(API.api_url_sending_an_application, {data}).then((res) => {
                var mess = "";
                if (res.data.check) {
                    mess = "Ваша заявка отправлена";
                    setOpen(true);
                    clear_inputs();
                } else {
                    mess = "Не удалось отправить заявку :(";
                }
                setResmes(mess);
            });
        } else {
            setResmes("Заполните поля правильно");
        }
    }

    //Валидация имени

    function validName(e) {
        var getInputTextValue = function (input) {
            return input.value.replace(/[a-zA-Z0-9]/g, "");
        };
        let clear_name = getInputTextValue(e.target);
        let mask_name = clear_name;

        if (clear_name.length < 2) {
            setNamecheck("is-invalid");
            setResmes("Пожалуйста укажите ваше имя");
        } else {
            setNamecheck("is-valid");
            mask_name = clear_name.replace(/(^|\s)\S/g, function (a) {
                return a.toUpperCase();
            });
            setResmes("");
        }

        setName(mask_name);
    }

    //Валидация и Маски для телефона

    function validPhone(e) {
        var getInputNumbersValue = function (input) {
            return input.value.replace(/\D/g, "");
        };

        let clear_num = getInputNumbersValue(e.target);
        let mask_num = e.target.value;

        if (clear_num.length >= 11) {
            setPhonecheck("is-valid");
            setResmes("");
        } else {
            setPhonecheck("is-invalid");
            setResmes("Пожалуйста, укажите ваш телефон");
        }

        if (["7", "8", "9"].indexOf(clear_num[0]) > -1) {
            if (clear_num[0] === "9") clear_num = "7" + clear_num;

            var firstSymbols = clear_num[0] === "8" ? "8" : "+7";

            mask_num = firstSymbols + " ";

            if (clear_num.length > 1) {
                mask_num += "(" + clear_num.substring(1, 4);
            }
            if (clear_num.length >= 5) {
                mask_num += ") " + clear_num.substring(4, 7);
            }
            if (clear_num.length >= 8) {
                mask_num += "-" + clear_num.substring(7, 9);
            }
            if (clear_num.length >= 10) {
                mask_num += "-" + clear_num.substring(9, 11);
            }
        } else {
            mask_num = "+" + clear_num.substring(0, 16);
        }

        setPhone(mask_num);
    }

    function onPhoneKeyDown(e) {
        var inputValue = e.target.value.replace(/\D/g, "");
        if (e.keyCode === 8 && inputValue.length === 1) {
            e.target.value = "";
            setPhone("");
        }
    }

    function onMessageKeyDown(e) {
        setMessage(e.target.value);
        setMessagecheck("is-valid");
    }

    return (
        <div className="fonimgsend">
            <div className="fade-black">
                <div className="container abs-center" style={{height: "100vh"}}>
                    <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <div className="p-5 bg-success text-white text-center">
                            <h2>Заявка отправлена</h2>
                            <p>Ожидайте звонка, наш специалист свяжется с вами</p>
                            <Button variant="light" onClick={handleClose}>
                                Закрыть
                            </Button>
                        </div>
                    </Dialog>
                    <div id="SendApplication" className="row adaptivform">
                        <Fade top>
                            <h2 className="text-center text-white p-5 m-0">Оставить заявку</h2>
                        </Fade>
                        <Fade bottom>
                            <Form className="p-5 rounded bg-white-al transparent">
                                <div  className="non-transparent">
                                    <p className="pb-2 m-0">Вы можете получить бесплатную консультацию позвонив на номер
                                        <a className="pb-2 m-0 text-black text-decoration-none" href={`tel: ${PHONE}`}>
                                            {' ' + PHONE_WITHOUT_NAME}
                                        </a>
                                        , или оставив свои контакты в этой форме.
                                    </p>

                                    <p className="text-center m-0">{resMess}</p>
                                    <Row>
                                        <Col xs={12} md={6} className="pt-3 non-transparent">
                                            <Form.Group controlId="formBasicName">
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Имя"
                                                    className={`${namecheck}`}
                                                    value={name}
                                                    onChange={(e) => validName(e)}
                                                    onsubmit="ym(89173057, 'reachGoal', 'fillName'); return true;"
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={6} className="pt-3">
                                            <Form.Group controlId="formBasicPhone">
                                                <Form.Control
                                                    type="tel"
                                                    placeholder="Телефон"
                                                    className={`${phonecheck}`}
                                                    value={phone}
                                                    onChange={(e) => validPhone(e)}
                                                    onKeyDown={(e) => onPhoneKeyDown(e)}
                                                    onsubmit="ym(89173057, 'reachGoal', 'fillPhone'); return true;"
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={24} md={12} className="pt-3" >
                                            <Form.Group controlId="formBasicMessage">
                                                <Form.Control as="textarea" rows={3}
                                                    type="text"
                                                    placeholder="Сообщение. Во сколько звонить, какую машину ищем или иная услуга. Можно оставить пустым."
                                                    className={`${messagecheck}`}
                                                    value={message}
                                                    onChange={(e) => onMessageKeyDown(e)}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="justify-content-center">
                                        <Col xs={12} md={6} className="pt-3">
                                            <Button
                                                className="w-100"
                                                variant="warning"
                                                onClick={(e) => Send_data(e)}
                                            >
                                                Отправить заявку
                                            </Button>
                                        </Col>
                                    </Row>
                                </div>
                            </Form>
                        </Fade>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SendApplication;
