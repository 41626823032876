import React from "react";
import Home from './pages';
import MyNavbar from "./Navbar/MyNavbar";

import SendApplication from "./Send/SendApplication";
import Services from "./Services/Services";
import CJMMap from "./CJMMap/CJMMap";
import Questions from "./Questions/Questions";
import Achievements from "./Achievements/Achievements";
import Cars from "./NewCars/Cars";
import Comments from "./Comments/Comments";
import Contacts from "./Contacts/Contacts";
import Footer from "./Footer/Footer";
import Infobox1 from "./Infobox1/Infobox1";
import Infobox2 from "./Infobox2/Infobox2";

import {Container} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style.css";

function App() {
    const Ref_about_us = React.useRef(null); // о нас
    const Ref_our_services = React.useRef(null); // наши услуги
    const Ref_reviews = React.useRef(null); // Отзывы
    const Ref_questions = React.useRef(null); // Частые вопросы
    const Ref_request = React.useRef(null); // Отправка заявки
    const Ref_contacts = React.useRef(null); // Контакты
    const Ref_cars = React.useRef(null); // Привезённые авто
    return (
        <div className="p-0 m-0">
            <MyNavbar
                Ref_about_us={Ref_about_us}
                Ref_our_services={Ref_our_services}
                Ref_reviews={Ref_reviews}
                Ref_questions={Ref_questions}
                Ref_contacts={Ref_contacts}
                Ref_request={Ref_request}
                Ref_cars={Ref_cars}
            />

            <a name="Ref_about_us"></a>
            <div ref={Ref_about_us}>
                <Infobox1/>
            </div>

            <a name="Ref_our_services"></a>
            <div ref={Ref_our_services}>
                <Services/>
            </div>

            <Container>
                <CJMMap/>
            </Container>

            <a name="Ref_cars"></a>
            <div  ref={Ref_cars}>
                <Cars
                    Ref_request={Ref_request}
                />
            </div>

            <Achievements/>

            <a name="Ref_reviews"></a>
            <div ref={Ref_reviews}>
                <Comments/>
                {/*<Container>*/}
                {/*    <Infobox2/>*/}
                {/*</Container>*/}
            </div>

            <a name="Ref_questions"></a>
            <div ref={Ref_questions}>
                <Questions/>
            </div>

            <a name="Ref_request"></a>
            <div ref={Ref_request}>
                <SendApplication/>
            </div>

            <a name="Ref_contacts"></a>
            <div ref={Ref_contacts}>
                {/*<Contacts/>*/}
                <Footer/>
            </div>
        </div>
    );
}

export default App;