//main setting
const PHONE = 89143252520;
const PHONE_MASK = "+7 (914) 325-25-20 Николай";
//const PHONE2 = 89996181893;
//const PHONE2_MASK = "+7 (999) 618-18-93 Алесь";
const PHONE3 = 89648072016;
const PHONE3_MASK = "+7 (964) 807‑20‑16 Юлия";
const ADDRESS = "г. Владивосток, ул. Русская, д. 92, каб. 7";
const CURRENT_YEAR = (new Date()).getFullYear();
const PHONE_WITHOUT_NAME = "+7 (914) 325-25-20";
const PHONE4 = 89609959926;
const PHONE4_MASK = "+7 (960) 995-99-26 Дамир";
const PHONE5 = 89841970562;
const PHONE5_MASK = "+7 (984) 197-05-62 Артём";
//EXPORTS
module.exports = {
    PHONE: PHONE,
    PHONE_MASK: PHONE_MASK,
    //PHONE2: PHONE2,
    //PHONE2_MASK: PHONE2_MASK,
    PHONE3: PHONE3,
    PHONE3_MASK: PHONE3_MASK,
    PHONE4: PHONE4,
    PHONE4_MASK: PHONE4_MASK,
    PHONE5: PHONE5,
    PHONE5_MASK: PHONE5_MASK,
    ADDRESS: ADDRESS,
    CURRENT_YEAR: CURRENT_YEAR,
    PHONE_WITHOUT_NAME: PHONE_WITHOUT_NAME
};