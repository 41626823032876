import React from "react";
import Accordion from "react-bootstrap/Accordion";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Questions.css";
import BookmarkIcon from "@mui/icons-material/Bookmark";

const QuestionsAnswers = [
  {
    q: "Может ли измениться стоимость автомобиля до момента его прихода в Россию?",
    a: "Да, так как все расходы по Японии, фрахт и стоимость автомобиля могут меняться в зависимости от курса валют.",
  },
  {
    q: "Могу ли я поставить ставку сразу на несколько автомобилей?",
    a: "Конечно, можете, но перед тем как поставить ставку на несколько автомобилей, вас проконсультирует личный менеджер.",
  },
  {
    q: "Если я живу в другом городе, как я смогу заключить с вами договор?",
    a: "Вы можете оставить заявку на нашем сайте, либо позвонить/написать в Whats’App или Telegram, после чего с вами свяжется наш менеджер и поможет составить договор.",
  },
  {
    q: "Смогу ли я привезти машину 2003 года под полную пошлину?",
    a: "Автомобили старше 2007 года привезти можно, но получить ПТС на такой автомобиль не получится так как по техническому регламенту на автомобили старше 2007 года СБКТС получить невозможно.",
  },
  {
    q: "Кто несёт ответственность за целостность моего автомобиля во время транспортировки?",
    a: "Всю ответственность от покупки автомобиля до его прибытия в порт г. Владивосток по условиям договора несём мы.",
  },
  {
    q: "Как разобраться со всеми записями в аукционном листе?",
    a: "Мы обязательно переводим аукционный лист перед покупкой. И только после этого, с Вашего согласия делаем ставку на автомобиль.",
  },
  {
    q: "Что если на фотографиях автомобиля на аукционе в багажнике лежат колеса?",
    a: "Иногда японцы вкладывают в продаваемый автомобиль комплект резины. Для покупателя такое вложение обычно становится приятным сюрпризом.",
  },
  {
    q: "Машина продана дешевле моей ставки, но купили не мы. Почему?",
    a: "Если на один автомобиль несколько заявок, то автомобиль отдается клиенту, поставившему максимальную ставку. Когда автомобили из списка «купить один» торгуются практически в одно время с интервалом в минуту или две, есть вероятность пропустить один авто, торгуясь за другой.",
  },
];

function Questions() {
  return (
    <div className="bg-dark">
      <div className="container">
        <div className="pb-5">
          <h2 className="text-center p-5 text-white">
            Часто задаваемые вопросы
          </h2>
          <Accordion>
            {QuestionsAnswers.map((item, i) => (
              <Accordion.Item eventKey={i} key={i}>
                <Accordion.Header>
                  <h6 className="m-0">
                    <span
                      className="text-warning"
                      style={{ paddingRight: "10px" }}
                    >
                      <BookmarkIcon />
                    </span>
                    {item.q}
                  </h6>
                </Accordion.Header>
                <Accordion.Body>{item.a}</Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </div>
      </div>
    </div>
  );
}

export default Questions;
