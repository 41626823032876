import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./CarCard.css";
import {Nav} from "react-bootstrap";

const scrollToRef = (ref) =>
    window.scrollTo(0, ref.current.offsetTop - 50, "smooth");

function CarCard(props) {
    const [open, setOpen] = React.useState(false);
    const [vcomm, setVcomm] = React.useState("");
    const [htext, sethtext] = React.useState("6em");
    const [button_comm_text, setButton_comm_text] = React.useState("Далее");
    const request = () => scrollToRef(props.Ref_request);

    function getDMY(date) {
        let day = new Date(date);
        day = new Date(day.toUTCString());
        let dateDay = day.getDate() < 10 ? '0' + day.getDate() : day.getDate();
        let dateMonth = day.getMonth() + 1 < 10 ? "0" + (day.getMonth() + 1) : day.getMonth() + 1;
        let dateYear = day.getFullYear();
        return dateDay + '.' + dateMonth + '.' + dateYear;
    }

    React.useEffect(() => {
        if (open) {
            setVcomm(props.text);
            setButton_comm_text("Свернуть");
            sethtext("100%");
        } else {
            setVcomm(`${props.text.substr(0, 140)} ...`);
            setButton_comm_text("Далее");
            sethtext("6em");
        }
    }, [open, props.text]);

    const carImg = require('../Media/success_orders/' + props.carID + '.png').default;

    return (
        <div style={{ paddingTop: "50px" }} className="position-static">
            <div className="border rounded shadow-sm col m-2">
                <div className="car-card">
                    <h5 className="">{props.model}</h5>
                    <img className="car-photo" alt={props.model} src={carImg}/>
                    <div className="car-description">
                        <div className="car-table">
                            <div className="car-table-col1">
                                <div className="car-row">
                                    <p className="car-header">Год:</p>
                                    <p className="car-prop">{props.year}</p>
                                </div>
                                <div className="car-row">
                                    <p className="car-header">Пробег:</p>
                                    <p className="car-prop">{props.mileage.toLocaleString() + " км."}</p>
                                </div>
                            </div>
                            <div className="car-table-col2">
                                <p className="car-text">{props.text + ' ' + getDMY(props.time)}</p>
                            </div>
                        </div>
                        <p className="car-text">{'Общая стоимость: ' + props.price.toLocaleString()} &#8381;</p>
                    </div>
                </div>
                <Nav.Link className="text-center text-warning bg-dark border rounded" onClick={request}>Подобрать похожую</Nav.Link>
            </div>
        </div>
    );
}

export default CarCard;
