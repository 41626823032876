import React, {useState, useEffect} from "react";
import NewCars from "./NewCars";
import ForumIcon from "@mui/icons-material/Forum";
import "./Cars.css";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import CarsCarousel from "../NewCars/NewCars";
import CommentsForm from "../Comments/CommentsForm";

const API = require("../API/API_config");

function Cars(props) {
    const [cars_api, setCars_api] = useState([]);
    const getCars_api = async () => {
        const res = await axios.get(API.api_url_getting_all_cars);
        setCars_api(res.data.result);
    };

    useEffect(() => {
        getCars_api();
    }, []);

    return (
        <div className="container">
            <div className="">
                <CarsCarousel
                    cars={cars_api}
                    Ref_request={props.Ref_request}
                />
            </div>
        </div>
    );
}

export default Cars;
