import React from "react";
import { Form, Button, Col, Row } from "react-bootstrap";
import Rating from "@mui/material/Rating";
import axios from "axios";
import "./CommentsForm.css";
import "bootstrap/dist/css/bootstrap.min.css";

const API = require("../API/API_config");

function Comments_form(props) {
	const [nickname, setNickname] = React.useState("");
	const [comm, setComm] = React.useState("");
	const [stars, setStars] = React.useState(0);
	// const [pincode, setPincode] = React.useState(0);

	const [nicknamecheck, setNicknamecheck] = React.useState("");
	const [commcheck, setCommcheck] = React.useState("");
	// const [pincodecheck, setPincodecheck] = React.useState("");

	const [info, setInfo] = React.useState("");

	function clear_inputs() {
		setNickname("");
		setComm("");
		setStars(0);
		// setPincode(0);
		setNicknamecheck("");
		setCommcheck("");
		// setPincodecheck("");
	}

	function validNickname(e) {
		var getInputTextValue = function (input) {
			return input.value.replace(/[a-zA-Z0-9]/g, "");
		};
		let clear_name = getInputTextValue(e.target);
		let mask_name = clear_name;

		if (clear_name.length < 2) {
			setNicknamecheck("is-invalid");
			setInfo("Пожалуйста укажите ваш Имя");
		} else {
			setNicknamecheck("is-valid");
			mask_name = clear_name.replace(/(^|\s)\S/g, function (a) {
				return a.toUpperCase();
			});
			setInfo("");
		}

		setNickname(mask_name);
	}

	function validComm(e) {
		var getInputTextValue = function (input) {
			return input.value.replace(/[{;};-;+;=;#;@]/g, "");
		};
		let clear_name = getInputTextValue(e.target);
		let mask_name = clear_name;

		if (clear_name.length < 2) {
			setCommcheck("is-invalid");
			setInfo("Пожалуйста укажите комментарий");
		} else {
			setCommcheck("is-valid");
			mask_name = clear_name;
			setInfo("");
		}
		setComm(mask_name);
	}

	// function validPincode(e) {
	// 	var getInputTextValue = function (input) {
	// 		return input.value.replace(/\D/g, "");
	// 	};
	//
	// 	let clear_name = getInputTextValue(e.target);
	// 	let mask_name = clear_name;
	//
	// 	if (clear_name.length < 4) {
	// 		setPincodecheck("is-invalid");
	// 		setInfo("Пожалуйста укажите код");
	// 	} else {
	// 		setPincodecheck("is-valid");
	// 		mask_name = clear_name;
	// 		setInfo("");
	// 	}
	// 	setPincode(mask_name);
	// }

	function SendComment(event) {
		if (
			nicknamecheck === "is-valid" &&
			commcheck === "is-valid" //&&
			// pincodecheck === "is-valid"
		) {
			event.preventDefault();
			const data = {
				nickname: nickname,
				stars: stars,
				comm: comm,
				pincode: 12345,
			};

			axios.post(API.api_url_creating_a_new_comment, { data }).then((res) => {
				var mess = "";
				if (res.data.check) {
					mess = "Ваш отзыв добавлен! ";
					props.getcommapi();
					clear_inputs();
				} else {
					mess = "Не удалось добавить отзыв ";
				}
				setInfo(mess);
			});
		} else {
			setInfo("Заполните все поля");
		}
	}

	return (
		<div id="addcomment">
			<Form className="shadow bg-white rounded" style={{ padding: "5%" }}>
				<h4 className="text-center p-2 m-0">Оставить отзыв</h4>
				<p className="text-center pb-2 ">{info}</p>
				<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
					<Form.Label>Имя</Form.Label>
					<Form.Control
						type="text"
						placeholder="Алексей"
						className={`not-radius ${nicknamecheck}`}
						value={nickname}
						onChange={(e) => validNickname(e)}
					/>
				</Form.Group>
				<Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
					<Form.Label>Ваш отзыв</Form.Label>
					<Form.Control
						placeholder="максимум 150 символов"
						as="textarea"
						rows={2}
						maxLength="1000"
						className={`not-radius ${commcheck}`}
						value={comm}
						onChange={(e) => validComm(e)}
					/>
				</Form.Group>
				<Form.Group className="mb-3">
					<Rating
						size="large"
						className="w-100"
						name="simple-controlled"
						value={stars}
						onChange={(e) => setStars(parseInt(e.target.value))}
					/>
				</Form.Group>
				{/*<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">*/}
				{/*	<Form.Label>Личный ключ</Form.Label>*/}
				{/*	<Form.Control*/}
				{/*		type="tel"*/}
				{/*		placeholder="12345"*/}
				{/*		className={`not-radius ${pincodecheck}`}*/}
				{/*		value={pincode}*/}
				{/*		onChange={(e) => validPincode(e)}*/}
				{/*	/>*/}
				{/*</Form.Group>*/}
				<Row className="justify-content-center w-100">
					<Col md={7}>
						<Button
							className="m-1 not-radius w-100"
							variant="warning"
							onClick={(e) => SendComment(e)}
						>
							Разместить отзыв
						</Button>
					</Col>
				</Row>
			</Form>
		</div>
	);
}

export default Comments_form;

//<Form.Label>Ваша оценка нашей работы : {stars}</Form.Label>
