import React from "react";
import {ADDRESS, PHONE, PHONE2, PHONE3, PHONE4, PHONE5, PHONE_MASK, PHONE2_MASK, PHONE3_MASK, PHONE4_MASK, PHONE5_MASK, CURRENT_YEAR} from "../Confinfo";
import { Container } from "react-bootstrap";
import instagram from "../Media/instagramm.png";
import telegram from "../Media/telegram.png";
import youtube from "../Media/youtube.png";
import vk from "../Media/vk.png";
import tiktok from "../Media/tiktok.png";
import whatsapp from "../Media/whatsapp.png";
import AutoAwesomeMosaicIcon from "@mui/icons-material/AutoAwesomeMosaic";
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import ExploreIcon from "@mui/icons-material/Explore";

import "bootstrap/dist/css/bootstrap.min.css";
import "./Footer.css";
let url_vk = "";
let url_youtube = "https://www.youtube.com/@KAWAI5584";
let url_whatsapp = "whatsapp://send?phone=79143252520";
let url_telegram = "https://telegram.me/JapanAuto38";
let url_instagram = "";
let url_tiktok = "";

function Footer() {
  return (
    <footer className="bg-dark text-white">
      <Container>
        <div className="row">
          <div className="col-lg-4 text-center pt-5">
            <h5 className="pb-3">
              Соцсети
              <span className="text-warning" style={{ paddingLeft: "10px" }}>
                <AutoAwesomeMosaicIcon />
              </span>
            </h5>
            <div className="row justify-content-center">
              <a href={url_telegram} className="aimg">
                <img
                  className="w-100"
                  src={telegram}
                  alt="Логотип телеграм"
                />
              </a>

              <a href={url_whatsapp} className="aimg">
                <img className="w-100" src={whatsapp} alt="картинка телеграм" />
              </a>

          {/*    <a href={url_vk} className="aimg">*/}
          {/*      <img className="w-100" src={vk} alt="картинка телеграм" />*/}
          {/*    </a>*/}
          {/*  </div>*/}
          {/*  <div className="row justify-content-center">*/}
          {/*    <a href={url_instagram} className="aimg">*/}
          {/*      <img*/}
          {/*        className="w-100"*/}
          {/*        src={instagram}*/}
          {/*        alt="картинка телеграм"*/}
          {/*      />*/}
          {/*    </a>*/}
          {/*    <a href={url_tiktok} className="aimg">*/}
          {/*      <img className="w-100" src={tiktok} alt="картинка телеграм" />*/}
          {/*    </a>*/}
          {/*    <a href={url_youtube} className="aimg">*/}
          {/*      <img className="w-100" src={youtube} alt="картинка телеграм" />*/}
          {/*    </a>*/}
            </div>
          </div>
          <div className="col-lg-4 pt-5 text-center">
            <h5 className="pb-3 text-center">
              <span className="text-warning" style={{ paddingLeft: "10px" }}>
                <FormatListBulletedIcon />
              </span>
              Реквизиты
            </h5>
            {/*<p className="mb-1">*/}
            {/*  <span className="text-warning">Адрес: </span>*/}
            {/*  {ADDRESS}*/}
            {/*</p>*/}
            <div className="phone-table">
              <div className="text-center">
                <p>ООО &laquo;Каваи гроуп&raquo;</p>
              </div>
              <div className="phone-row">
                <span className="text-warning phone-inn">ОГРН:</span>
                <p className="INN">1222500010490</p>
              </div>
              <div className="phone-row">
                <span className="text-warning phone-inn">ИНН:</span>
                <p className="INN">2543165489</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 text-center pt-5">
            <h5 className="pb-3 text-center">
              <span className="text-warning">
                <LocalPhoneIcon />
              </span>
              Телефоны
            </h5>
            <h5 className="pb-3 text-center footer-header text-warning">
              Автомобили
            </h5>
            <div className="phone-table">
              <div className="phone-row">
                <span className="text-warning phone-city">Владивосток:</span>
                <a className="a-s-none" href={`tel: ${PHONE}`}>
                  {PHONE_MASK}
                </a>
              </div>
              <div className="phone-row">
                <span className="text-warning phone-city">Иркутск:</span>
                <a className="a-s-none" href={`tel: ${PHONE3}`}>
                  {PHONE3_MASK}
                </a>
              </div>
              <div className="phone-row">
                <span className="text-warning phone-city">Омск:</span>
                <a className="a-s-none" href={`tel: ${PHONE4}`}>
                  {PHONE4_MASK}
                </a>
              </div>
              <div className="phone-row">
                <span className="text-warning phone-city">Спасск-Дальний:</span>
                <a className="a-s-none" href={`tel: ${PHONE5}`}>
                  {PHONE5_MASK}
                </a>
              </div>
            </div>
            {/*<h5 className="pb-3 text-center footer-header text-warning">
              Запчасти
            </h5>
            <p className="mb-1">
              <a className="a-s-none" href={`tel: ${PHONE2}`}>
                {PHONE2_MASK}
              </a>
            </p>*/}
          </div>
          {/*<div className="col-lg-4 text-center pt-5">*/}
          {/*  <h5 className="pb-3">*/}
          {/*    Меню*/}
          {/*    <span className="text-warning" style={{ paddingLeft: "10px" }}>*/}
          {/*      <ExploreIcon />*/}
          {/*    </span>*/}
          {/*  </h5>*/}
          {/*</div>*/}
        </div>
        <small className="d-block pb-5 text-muted text-center">
          ©2017-{CURRENT_YEAR}
        </small>
      </Container>
    </footer>
  );
}

export default Footer;
