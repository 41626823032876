import React from "react";
import CardComm from "./CardComm";
import Carousel from "react-multi-carousel";
import CircularProgress from "@mui/material/CircularProgress";

import "bootstrap/dist/css/bootstrap.min.css";
import "react-multi-carousel/lib/styles.css";

function Comments_carousel(props) {
  if (props.comments.length === 0) {
    return (
      <div className="text-center p-5">
        <CircularProgress color="inherit" />
      </div>
    );
  }

  return (
    <div id="comments">
      <div className="text-center pt-5 pb-3 m-0">
        <h2>Отзывы наших клиентов</h2>
        {/*<p>Наши клиенты оставили {props.comments.length} отзывов. </p>*/}
      </div>

      <Carousel
        additionalTransfrom={0}
        arrows
        autoPlay={true}
        autoPlaySpeed={8000}
        centerMode={false}
        className="pb-3"
        containerClass="container-with-dots"
        dotListClass=""
        draggable
        focusOnSelect={false}
        infinite
        itemClass=""
        keyBoardControl
        minimumTouchDrag={80}
        renderButtonGroupOutside={false}
        renderDotsOutside={false}
        responsive={{
          desktop: {
            breakpoint: {
              max: 3000,
              min: 1024,
            },
            items: 3,
            partialVisibilityGutter: 40,
          },
          mobile: {
            breakpoint: {
              max: 464,
              min: 0,
            },
            items: 1,
            partialVisibilityGutter: 30,
          },
          tablet: {
            breakpoint: {
              max: 1024,
              min: 464,
            },
            items: 2,
            partialVisibilityGutter: 30,
          },
        }}
        showDots={false}
        sliderClass=""
        slidesToSlide={1}
        swipeable
      >
        {props.comments.map((item, i) => (
          <CardComm
            key={item.id}
            name={item.name}
            reiting_stars={item.reiting_stars}
            comment={item.comment}
            time={item.time}
          />
        ))}
      </Carousel>
    </div>
  );
}

export default Comments_carousel;
