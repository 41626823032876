import React from "react";
import CarCard from "./CarCard";
import Carousel from "react-multi-carousel";
import CircularProgress from "@mui/material/CircularProgress";

import "bootstrap/dist/css/bootstrap.min.css";
import "react-multi-carousel/lib/styles.css";

function NewCars(props) {
    if (props.cars.length === 0) {
        return (
            <div className="text-center p-5">
                <CircularProgress color="inherit" />
            </div>
        );
    }

    return (
        <div id="cars">
            <div className="text-center m-0">
                <h2>Привезённые автомобили</h2>
            </div>

            <Carousel
                additionalTransfrom={0}
                arrows
                autoPlay={true}
                autoPlaySpeed={8000}
                centerMode={false}
                className="pb-3"
                containerClass="container-with-dots"
                dotListClass=""
                draggable
                focusOnSelect={false}
                infinite
                itemClass=""
                keyBoardControl
                minimumTouchDrag={80}
                renderButtonGroupOutside={false}
                renderDotsOutside={false}
                responsive={{
                    desktop: {
                        breakpoint: {
                            max: 3000,
                            min: 1400,
                        },
                        items: 5,
                        partialVisibilityGutter: 40,
                    },
                    tablet: {
                        breakpoint: {
                            max: 1399,
                            min: 1200,
                        },
                        items: 4,
                        partialVisibilityGutter: 30,
                    },
                    compact: {
                        breakpoint: {
                            max: 1199,
                            min: 992,
                        },
                        items: 3,
                        partialVisibilityGutter: 30,
                    },
                    small: {
                        breakpoint: {
                            max: 991,
                            min: 576,
                        },
                        items: 2,
                        partialVisibilityGutter: 30,
                    },
                    mobile: {
                        breakpoint: {
                            max: 575,
                            min: 0,
                        },
                        items: 1,
                        partialVisibilityGutter: 30,
                    },
                }}
                showDots={false}
                sliderClass=""
                slidesToSlide={1}
                swipeable
            >
                {props.cars.map((item, i) => (
                    <CarCard
                        carID   = {item.id}
                        model   = {item.model}
                        year    = {item.year}
                        mileage = {item.mileage}
                        price   = {item.price}
                        text    = {item.text}
                        time    = {item.time}
                        Ref_request = {props.Ref_request}
                    />
                ))}
            </Carousel>
        </div>
    );
}

export default NewCars;
