import React from "react";
import Flip from "react-reveal/Flip";
import DescriptionIcon from "@mui/icons-material/Description";
import GavelIcon from "@mui/icons-material/Gavel";
import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat";
import LocalPoliceIcon from "@mui/icons-material/LocalPolice";
import CarRepairIcon from "@mui/icons-material/CarRepair";
import "bootstrap/dist/css/bootstrap.min.css";
import "./CJMMap.css";

function CJMMap() {
  return (
    <div>
      <div className="text-center pt-5 m-0">
        <h2>Карта работ</h2>
      </div>
      <div className="row pt-5 pb-5 p-3">
        <Flip top duration={2000}>
          <div className="col-jsm-step-box">
            <div className="row">
              <div className="col-jsm-step p-0 m-0">
                <div className="abs-center w-100 h-100 p-3 pt-5 pb-5">
                  <DescriptionIcon sx={{ fontSize: 50 }} />
                </div>
              </div>
              <div className="col-jsm-step-way p-0 m-0">
                <div className="jsm-long-way stripe-l position-relative p-0 m-0"></div>
              </div>
              <div className="col-jsm-step p-0 m-0 abs-center">
                <p className="m-0 p-3 pt-5 pb-5">Оформление договора</p>
              </div>
            </div>
          </div>

          <div className="col-jsm-step-box">
            <div className="row">
              <div className="col-jsm-step p-0 m-0">
                <div className="abs-center w-100 h-100 p-3 pt-5 pb-5">
                  <GavelIcon sx={{ fontSize: 50 }} />
                </div>
              </div>
              <div className="col-jsm-step-way p-0 m-0">
                <div className="jsm-long-way position-relative p-0 m-0"></div>
              </div>
              <div className="col-jsm-step p-0 m-0 abs-center">
                <p className="m-0 p-3 pt-5 pb-5">
                  Подбор и покупка на аукционе
                </p>
              </div>
            </div>
          </div>

          <div className="col-jsm-step-box">
            <div className="row">
              <div className="col-jsm-step p-0 m-0">
                <div className="abs-center w-100 h-100 p-3 pt-5 pb-5">
                  <DirectionsBoatIcon sx={{ fontSize: 50 }} />
                </div>
              </div>
              <div className="col-jsm-step-way p-0 m-0">
                <div className="jsm-long-way position-relative p-0 m-0"></div>
              </div>
              <div className="col-jsm-step p-0 m-0 abs-center">
                <p className="m-0 p-3 pt-5 pb-5">Отправка во Владивосток</p>
              </div>
            </div>
          </div>

          <div className="col-jsm-step-box">
            <div className="row">
              <div className="col-jsm-step p-0 m-0">
                <div className="abs-center w-100 h-100 p-3 pt-5 pb-5">
                  <LocalPoliceIcon sx={{ fontSize: 50 }} />
                </div>
              </div>
              <div className="col-jsm-step-way p-0 m-0">
                <div className="jsm-long-way position-relative p-0 m-0"></div>
              </div>
              <div className="col-jsm-step p-0 m-0 abs-center">
                <p className="m-0 p-3 pt-5 pb-5">Таможенное оформление</p>
              </div>
            </div>
          </div>
          <div className="col-jsm-step-box">
            <div className="row">
              <div className="col-jsm-step p-0 m-0">
                <div className="abs-center w-100 h-100 p-3 pt-5 pb-5">
                  <CarRepairIcon sx={{ fontSize: 50 }} />
                </div>
              </div>
              <div className="col-jsm-step-way p-0 m-0">
                <div className="jsm-long-way stripe-r position-relative p-0 m-0"></div>
              </div>
              <div className="col-jsm-step p-0 m-0 abs-center">
                <p className="m-0 p-3 pt-5 pb-5">Отправка в регион</p>
              </div>
            </div>
          </div>
        </Flip>
      </div>
    </div>
  );
}

export default CJMMap;
