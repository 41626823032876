import React, {useState, useEffect} from "react";
import CommentsCarousel from "./CommentsCarousel";
import CommentsForm from "./CommentsForm";
import ForumIcon from "@mui/icons-material/Forum";
import "./Comments.css";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";

const API = require("../API/API_config");

function Comments() {
    const [comments_api, setComments_api] = useState([]);
    const getComments_api = async () => {
        const res = await axios.get(API.api_url_getting_all_comments);
        setComments_api(res.data.result);
    };

    useEffect(() => {
        getComments_api();
    }, []);

    return (
        <div className="container">
            <div className="">
                <CommentsCarousel comments={comments_api}/>
                <div className="row bg-dark rounded comment-form">
                    <div className="abs-center col-md-6 m-0 p-0 order-md-2">
                        <div className="text-center text-white p-5">
                            <h2 className="text-warning">
                                <ForumIcon sx={{fontSize: 100}}/>
                            </h2>
                            <h3 className="text-white">
                                <b>Ваше мнение - это главное</b>
                            </h3>
                            <p>
                                Нам важно чтобы вы&nbsp;остались довольны. Если вы&nbsp;уже получили своё авто
                                и&nbsp;вам есть что сказать&nbsp;&mdash; поделитесь этим с&nbsp;нами.
                            </p>
                        </div>
                    </div>
                    <div className="col-md-6 p-3 order-md-1">
                        <CommentsForm getcommapi={getComments_api}/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Comments;
