//main setting
const ip_api = "kawaii-auto.ru";
const port_api = 8080;
const api_url = `https://${ip_api}:${port_api}`; // https !

//addresses for requests
const api_url_sending_an_application = api_url + "/api/sending_an_application";
const api_url_creating_a_new_comment = api_url + "/api/creating_a_new_comment";
const api_url_getting_all_comments = api_url + "/api/getting_all_comments";
const api_url_getting_all_cars = api_url + "/api/getting_all_cars";
const get_cars_quantity = api_url + "/api/get_cars_quantity";

//request settings
//sending_an_application {data} = { name: name, phone: phone }
//creating_a_new_comment {data} = { nickname: nickname, stars: stars, comm: comm, pincode: pincode};

//EXPORTS
module.exports = {
	api_url_sending_an_application: api_url_sending_an_application,
	api_url_creating_a_new_comment: api_url_creating_a_new_comment,
	api_url_getting_all_comments: api_url_getting_all_comments,
	api_url_getting_all_cars: api_url_getting_all_cars,
	get_cars_quantity: get_cars_quantity
};
