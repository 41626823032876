import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import boxs from "../Media/geo.png";
import "./Infobox1.css";
import Fade from "react-reveal/Fade";

//import DirectionsBoatFilledIcon from "@mui/icons-material/DirectionsBoatFilled";
//import FlagOutlinedIcon from "@mui/icons-material/FlagOutlined";

function Infobox1() {
  return (
    <div className="container about-us">
      <Fade top>
        <h1 className="font-weight-normal text-black pb-3 text-center">
          Автомобили под КЛЮЧ с&nbsp;аукционов Японии, Китай, Корея c&nbsp;Kawaii&nbsp;Auto&nbsp;&mdash; просто
          и&nbsp;надёжно!
        </h1>
      </Fade>
      <div className="w-100">
        <div className="row">
          <div className="col-lg-5 m-0 p-0 abs-center">
            <div className="text-left">
              <div className="p-3 ">
                <p className="">
                <b>Kawaii Auto</b>&nbsp;&mdash; компания с&nbsp;высоким уровнем сервиса оказания услуг по&nbsp;доставке автомобилей с&nbsp;японских аукционов, рынков Кореи и Китая.
                Мы&nbsp;помогаем нашим клиентам купить машину с&nbsp;аукциона в&nbsp;Японии, внутреннего рынка Кореи или Китая, без пробега по&nbsp;России. Доверяя всю работу нам, вы&nbsp;получаете возможность недорого купить машину в&nbsp;Японии или Корее с&nbsp;доставкой в&nbsp;РФ и&nbsp;быть уверенными в&nbsp;соблюдении договоренностей, сохранности вашего груза и&nbsp;денежных средств с&nbsp;момента заключения договора и&nbsp;до&nbsp;окончания сделки. Мы&nbsp;делаем свою работу открыто и&nbsp;публично.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-7 abs-center">
            <img src={boxs} alt="контейнеры для доставки" width="100%" />
          </div>
        </div>
        {/*
        <div className="row p-3 pt-5 pb-5">
          <div className="col-3 text-center p-0">
            <FlagOutlinedIcon /> Россия
          </div>
          <div className="col-6 abs-center p-0" id="shipdiv">
            <div className="line"></div>
            <div id="ship">
              <DirectionsBoatFilledIcon />
            </div>
          </div>
          <div className="col-3 text-center p-0">
            <FlagOutlinedIcon /> Япония
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default Infobox1;
