import React from "react";
import Rating from "@mui/material/Rating";
import "bootstrap/dist/css/bootstrap.min.css";
import "./CardComm.css";
import ava from "../Media/ava.svg";

function CardComm(props) {
  const [open, setOpen] = React.useState(false);
  const [vcomm, setVcomm] = React.useState("");
  const [htext, sethtext] = React.useState("6em");
  const [button_comm_text, setButton_comm_text] = React.useState(" Далее ");

  function getDMY(date) {
    var today = new Date(date);
    today = new Date(today.toUTCString());
    return `${today.getDate()}.${today.getMonth() + 1}.${today.getFullYear()}`;
  }

  React.useEffect(() => {
    if (open) {
      setVcomm(<p>&laquo;{props.comment}&raquo;</p>);
      setButton_comm_text(" Свернуть ");
      sethtext("100%");
    } else {
      setVcomm(<p>&laquo;{props.comment.substr(0, 140)} ...&raquo;</p>);
      setButton_comm_text(" Далее ");
      sethtext("6em");
    }
  }, [open, props.comment]);

  return (
    <div style={{ paddingTop: "50px" }} className="position-static">
      <div className="border rounded shadow-sm row  m-2">
        <div className="col-12 ImageOverlay position-relative">
          <img className="Image" alt="автовладелец" src={ava} />
        </div>

        <div className="col-12 p-3">
          <h5>{props.name}</h5>
          <Rating name="read-only" value={props.reiting_stars} readOnly />
        </div>
        <div className="col-12 p-3 pt-0">
          <div className="text-dark m-0">
            {props.comment.length > 150 ? (
              <p style={{ height: `${htext}` }}>
                {vcomm}
                <span
                  className="text-secondary"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setOpen(!open);
                  }}
                >
                  {button_comm_text}
                </span>
              </p>
            ) : (
              <p className="comment" style={{ height: "6em"}}>&laquo;{props.comment}&raquo;</p>
            )}
          </div>
        </div>
        <div className="col-12 p-3 pt-0 text-dark  ">
          <p className="m-0 text-end">{getDMY(props.time)}</p>
        </div>
      </div>
    </div>
  );
}

export default CardComm;
